import React from "react";
import CountryTabs from "./country-tabs.js";
import Data from "src/languageData/-Data.js";

const Contact = ( props ) => {
  const { lang } = props;
  return (
    <section className="Contact">
      <div className="padding-global">
        <div className="Contact__main">
          <div className="Contact__title">
            <span className="theme-main-color">
              <h3>{Data[ lang ][ "contactTitleFirst" ]}</h3>
            </span>
            <h2>
              {Data[ lang ][ "contactTitleSecond" ]}
              <span className="theme-main-color">
                {Data[ lang ][ "contactTitleThird" ]}
              </span>
            </h2>
          </div>
          <div className="Contact__main-bottom">
            <div className="Contact__main-bottom-left">
              <div className="Contact__office-title">
                <h3>{Data[ props.lang ].office}</h3>
              </div>
              <div className="Contact__country-tabs">
                <CountryTabs lang={ lang } />
              </div>
            </div>
            <div className="Contact__main-bottom-right">
              <div className="Contact__headquarters-title">
                <h3>{Data[ props.lang ].head}</h3>
              </div>
              <div className="bnl__title">BeNeLux</div>
              <div className="bnl__content">
                <div className="bnl__headquarters">
                  <div className="bnl__address-title">{Data[ lang ][ "contactAddressTitle" ]}:</div>
                  <div className="bnl__address">
                    <div>Huis ter Heideweg 18 & 58-1</div>
                    <div>3705 LZ, Zeist</div>
                    <div>The Netherlands</div>
                    <div>030 607 0880</div>
                  </div>
                </div>
                <div className="bnl__ceo">
                  <div><b>CEO - Paul Huis in ‘t veld</b></div>
                  <div>paul@gofilex.nl</div>
                </div>
                <div className="bnl__ceo">
                  <div><b>CTO - Cathy Huis in ‘t veld-Esser</b></div>
                  <div>cathy@gofilex.nl</div>
                </div>
                <div className="bnl__ceo">
                  <div><b>Operations - Bas Vermeulen</b></div>
                  <div>bas@gofilex.nl</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
