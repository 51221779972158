import React from "react";
import "src/components/language-selector/language-selector.scss";

import enFlagIcon from "src/components/assets/en-flag.webp";
import nlFlagIcon from "src/components/assets/nl-flag.webp";
import deFlagIcon from "src/components/assets/de-flag.webp";

const flagIcons = {
  en: enFlagIcon,
  nl: nlFlagIcon,
  de: deFlagIcon,
};

const LanguageSelector = ( props ) => {
  const { lang, currentFlag, availableLanguages, handleLanguage, toggleMenu } = props;

  const unselectedLanguages = availableLanguages.filter( language => language !== lang );

  return (
    <div className="LanguageSelector">
      <div className="LanguageSelector__language-selected">
        <div className="LanguageSelector__flag-icon">
          <img src={ currentFlag } alt={ `${ lang }-flag` } />
        </div>
        <div className="LanguageSelector__language-text">
          {lang.toUpperCase()}
        </div>
      </div>

      <div className="LanguageSelector__language-dropdown-items">
        {unselectedLanguages.map( ( option, index ) => (
          <div
            className="LanguageSelector__language-dropdown-item"
            key={ index }
            onClick={ () => {
              handleLanguage( { target: { value: option } } );
              if ( window.innerWidth < 1000 ) {
                toggleMenu();
              }
            } }
          >
            <div className="LanguageSelector__flag-icon">
              <img src={ flagIcons[ option ] } alt={ `${ option }-flag-option` } />
            </div>
            <div className="LanguageSelector__language-text">
              {option.toUpperCase()}
            </div>
          </div>
        ) )}
      </div>
    </div>
  );
};

export default LanguageSelector;
