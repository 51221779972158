import React from "react";

import Data from "src/languageData/-Data.js";

const Cinio = ( props ) => {
  const { lang } = props;
  return (
    <section className="Cinio">
      <div className="padding-global">
        <div className="Cinio__content">
          <h2 className="Cinio__title"><span>
            {Data[ lang ][ "cinioFirstTitle" ]}
          </span><span className="theme-main-color">
            {Data[ lang ][ "cinioSecondTitle" ]}
          </span></h2>
          <span className="Cinio__info">
            { Data[ props.lang ].CinioText.map( e => <div key={ e }><p>{ e }</p><br /></div> ) }
            <a className="Cinio__link" href="https://www.cinio.net/" target="_blank">Visit Cinio</a>
          </span>
        </div>
      </div>
    </section>
  );
};

export default Cinio;
