import React, { useState } from "react";
import "src/styles/common.scss";
import Data from "src/languageData/-Data.js";
import "src/components/cinema/cinema.scss";

const Cinema = ( props ) => {
  const { lang } = props;

  const [ readMore, setReadMore ] = useState( false );

  const extraContent = (
    <div>
      <span>
        {Data[ lang ].cinemaReadMore.map( ( e ) => (
          <div key={ e }>
            <p>{e}</p>
            <br />
          </div>
        ) )}
      </span>
    </div>
  );

  const linkName = readMore ? Data[ lang ][ "readLess" ] : Data[ lang ][ "readMore" ];

  return (
    <section className="Cinema">
      <div className="padding-global">
        <div className="Cinema__content">
          <h3 className="Cinema__title">
            <span className="theme-main-color">
              {Data[ lang ][ "cinemaTitleFirst" ]}
            </span>
          </h3>
          <h2>
            {Data[ lang ][ "cinemaTitleSecond" ]}
            <span className="theme-main-color">
              {Data[ lang ][ "cinemaTitleThird" ]}
            </span>
          </h2>
          <span className="Cinema__info">
            {Data[ lang ].cinemaText.map( ( e ) => (
              <div key={ e }>
                <p>{e}</p>
                <br />
              </div>
            ) )}
            <span className="text">{extraContent}</span>
            <span className="button">
              {readMore && extraContent}
              <div className="linkName" onClick={ () => setReadMore( !readMore ) }>
                {linkName}
              </div>
            </span>
          </span>
        </div>
      </div>
    </section>
  );
};

export default Cinema;
