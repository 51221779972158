import React, { useState } from "react";

import paulencathy from "src/components/assets/paulencathy.webp";

import Data from "src/languageData/-Data.js";

const AboutUs = ( props ) => {
  const { lang } = props;

  const [ readMore, setReadMore ] = useState( false );
  const [ isValuesOpen, setIsValuesOpen ] = useState( false );

  const toggleValues = () => {
    setIsValuesOpen( !isValuesOpen );
  };

  const extraContent = ( <div>
    <div>
      { Data[ props.lang ].aboutUsReadMore.map( e => <div key={ e }><p>{ e }</p><br /></div> ) }
    </div>
  </div> );

  const linkName = readMore ? Data[ lang ][ "readLess" ] : Data[ lang ][ "readMore" ];
  return (
    <section className="AboutUs">
      <div className="padding-global">
        <div className="AboutUs__content">
          <div className="AboutUs__title">
            <h3><span className="theme-main-color">
              {Data[ lang ][ "aboutUsTitleFirst" ]}
            </span></h3>
            <h2>
              {Data[ lang ][ "aboutUsTitleSecond" ]}
              <span className="theme-main-color">
                {Data[ lang ][ "aboutUsTitleThird" ]}
              </span>
            </h2>
          </div>

          <div className="AboutUs__info">
            <div className="AboutUs__general">
              <div className="AboutUs__general-text">
                { Data[ props.lang ].aboutUsText.map( e => <div key={ e }><p>{ e }</p><br /></div> ) }
                <div className="text">{ extraContent }</div>
                <div className="button">
                  { readMore && extraContent }
                  <div className="linkName" onClick={ () => setReadMore( !readMore ) }>{ linkName }</div>
                </div>
              </div>
              <div className="AboutUs__img-container">
                <img src={ paulencathy } alt="ceo-image" />
              </div>
            </div>
            <div className="AboutUs__values">
              <div className="AboutUs__values-title-container" onClick={ toggleValues }>
                <div className="AboutUs__values-title">
                  <h3>
                    {Data[ lang ][ "valuesTitle" ]}
                  </h3>
                </div>
                <div className={ `AboutUs__values-icon ${ isValuesOpen ? "open" : "closed" }` } />
              </div>
              <div className={ `AboutUs__values-info ${ isValuesOpen ? "open" : "closed" }` }>
                { Data[ props.lang ].list.map( ( e, i ) => <h4 key={ i }>{ e }</h4> ) }
              </div>
            </div>
            <div className="AboutUs__management">
              <div className="AboutUs__management-block">
                <h3 className="AboutUs__management-title">
                  <span className="theme-main-color">
                    {Data[ lang ][ "managementTitleFirst" ]}
                  </span>
                </h3>
                <h2>
                  {Data[ lang ][ "managementTitleSecond" ]}
                  <span className="theme-main-color">
                    {Data[ lang ][ "managementTitleThird" ]}
                  </span>
                </h2>
                <p className="AboutUs__management-block-info">
                  { Data[ props.lang ].managementText }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
