import en from "./en.json";
import nl from "./nl.json";
import de from "./de.json";
import fr from "./fr.json";
import pl from "./pl.json";
import fi from "./fi.json";
import sv from "./sv.json";

export default {
  en,
  nl,
  de,
  fr,
  pl,
  fi,
  sv,
};
