import React from "react";
import Video from "src/components/header/cinio.mp4";
import Data from "src/languageData/-Data.js";
import LazyLoad from "react-lazyload";
import ReactPlayer from "react-player";

const Header = ( props ) => {
  const { scrollToRef, lang } = props;

  return (
    <div className="header">
      <LazyLoad>
        <ReactPlayer
          url={ Video }
          style={ { width: "100%" } }
          playing
          muted
          loop
          config={ {
            file: {
              attributes: {
                preload: "auto",
              },
            },
          } }
        />
      </LazyLoad>
      <div
        className="header__learn-more"
        onClick={ () => scrollToRef( "forCinemas" ) }
      >
        {Data[ lang ][ "learnMoreButton" ]}
      </div>
    </div>
  );
};

export default Header;
