import React, { useState } from "react";
import Data from "src/languageData/-Data.js";

const data = [ {
  countryCode: "DE/AT",
  companyName: "Gofilex Germany GmbH",
  address: {
    street: "Dieburgerstrasse 218",
    city: "64287 Darmstadt",
    country: "Deutschland",
    phone: "+49 6151 8633394",
  },
  phoneNumbers: [
    { name: "", number: "+49 6151 8633394" },
  ],
  emails: [
    { name: "Roland Huis in ‘t veld", email: "roland@gofilex.de" },
    { name: "Harry Schusterov", email: "schusterov@gofilex.de" },
    { name: "Support", email: "support@gofilex.de" },
  ],
}, {
  countryCode: "Nordic",
  companyName: "Gofilex Nordic Oy",
  address: {
    street: "Katajanokanlaituri 11 B",
    city: "00160 Helsinki",
    country: "Suomi",
    phone: "+358 400 420 313",
  },
  phoneNumbers: [
    { name: "", number: "+358 400 420 313" },
  ],
  emails: [
    { name: "Petri Siitonen", email: "petri.siitonen@gofilexnordic.fi" },
    { name: "Support", email: "info@gofilexnordic.fi" },
  ],
}, {
  countryCode: "CH",
  companyName: "Gofilex Schweiz AG",
  address: {
    street: "Badenerstrasse 569",
    city: "8048 Zürich",
    country: "Schweiz",
    phone: "+41 44 500 3456",
  },
  phoneNumbers: [
    { name: "", number: "41 (0)44 500 3456" },
  ],
  emails: [
    { name: "Matthias Marti", email: "mmarti@gofilex.ch" },
    { name: "Harry Schusterov", email: "schusterov@gofilex.de" },
    { name: "Support", email: "support@gofilex.ch" },
  ],
}, {
  countryCode: "PL",
  companyName: "Gofilex International Polska",
  address: {
    street: "Brodzka 193A",
    city: "54-067 Wroclaw",
    country: "Polska",
    phone: "+48 60 979 24 12 (Nikolaos Theodosjadis)",
  },
  phoneNumbers: [
    { name: "Krzysztof Tameczka", number: "+48 79 049 86 54" },
    { name: "Nikolaos Theodosjadis", number: "+48 60 979 24 12" },
  ],
  emails: [
    { name: "Roland Huis in ‘t veld", email: "roland@gofilex.de" },
    { name: "Harry Schusterov", email: "schusterov@gofilex.de" },
    { name: "Support", email: "support@gofilex.pl" },
  ],
}, {
  countryCode: "UK",
  companyName: "MPS U.K.",
  address: {
    street: "Mission Hall 9-11",
    city: "W14 8ST London",
    country: "United Kingdom",
    phone: "+44 (0) 20 7751 7001",
  },
  phoneNumbers: [
    { name: "", number: "+44 (0) 20 7751 7001" },
  ],
  emails: [
    { name: "Tara Farrer", email: "tara.farrer@withmps.com" },
  ],
}, {
  countryCode: "APAC",
  companyName: "",
  address: {
    street: "",
    city: "",
    country: "",
    phone: "",
  },
  phoneNumbers: [
    { name: "", number: "" },
  ],
  emails: [
    { name: "Jason Harrod", email: "jason.harrod@withmps.com" },
  ],
}, {
  countryCode: "NA",
  companyName: "",
  address: {
    street: "",
    city: "",
    country: "",
  },
  phoneNumbers: [
    { name: "", number: "" },
  ],
  emails: [
    { name: "Paul Huis in ‘t veld", email: "paul@gofilex.nl" },
  ],
}, {
  countryCode: "LATAM",
  companyName: "",
  address: {
    street: "",
    city: "",
    country: "",
  },
  phoneNumbers: [
    { name: "", number: "" },
  ],
  emails: [
    { name: "Paul Huis in ‘t veld", email: "paul@gofilex.nl" },
  ],
},
];

const CountryTabs = ( props ) => {
  const { lang } = props;

  const [ selectedTab, setSelectedTab ] = useState( data[ 0 ] );

  const shouldRenderAddress = selectedTab.address && Object.values( selectedTab.address ).some( value => value !== "" );

  return (
    <div>
      <div className="tabs">
        {data.map( tab => (
          <div
            key={ tab.countryCode }
            className={ tab.countryCode === selectedTab.countryCode ? "is-selected" : "" }
            onClick={ () => setSelectedTab( tab ) }
          >
            {tab.countryCode}
          </div>
        ) )}
      </div>

      <div className="tabData">
        {shouldRenderAddress && (
          <div className="tabData__address">
            <div className="tabData__address-title">{Data[ lang ][ "contactAddressTitle" ]}:</div>
            {selectedTab.address.street && <div>{selectedTab.address.street}</div>}
            {selectedTab.address.city && <div>{selectedTab.address.city}</div>}
            {selectedTab.address.country && <div>{selectedTab.address.country}</div>}
            {selectedTab.address.phone && <div>{selectedTab.address.phone}</div>}
          </div>
        )}

        {selectedTab.emails.map( email => (
          <div key={ email.email } className="tabData__email">
            <div className="tabData__email--name">{email.name}</div>
            <div>{email.email}</div>
            <div>{email.phone}</div>
          </div>
        ) )}
      </div>
    </div>
  );
};

export default CountryTabs;
