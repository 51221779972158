import React, { useState } from "react";
import browserLanguage, { languages } from "src/util.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Main from "./components/main/main";
import DataDeletionPage from "./components/data-deletion/data-deletion";
import PrivacyPolicy from "./components/privacy-policy/privacy-policy";

const languageOptions = [ ...Object.keys( languages ) ].map( option => option );
const languageIndex = languageOptions.findIndex( language => language === browserLanguage );
if ( languageIndex ) {
  languageOptions.splice( languageIndex, 1 );
  languageOptions.unshift( browserLanguage );
}

const App = () => {
  const [ lang, setLanguage ] = useState( browserLanguage );

  const switchLanguage = ( language ) => {
    setLanguage( language );
  };

  const handleLanguage = ( e ) => {
    const selectedLanguage = e.target.value.toLowerCase();

    switchLanguage( selectedLanguage );
  };


  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <Main
              lang={ lang }
              languageOptions={ languageOptions }
              handleLanguage={ handleLanguage }
            />
          } />
          <Route path="/data-deletion" element={
            <DataDeletionPage
              lang={ lang }
              languageOptions={ languageOptions }
              handleLanguage={ handleLanguage }
            />
          } />
          <Route path="/privacy-policy" element={
            <PrivacyPolicy
              lang={ lang }
              languageOptions={ languageOptions }
              handleLanguage={ handleLanguage }
            />
          } />
          <Route path="*" element={
            <Main
              lang={ lang }
              languageOptions={ languageOptions }
              handleLanguage={ handleLanguage }
            />
          } />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
