import React, { useEffect, useState } from "react";
import browserLanguage from "src/util.js";
import Data from "src/languageData/-Data.js";
import ScrollUp from "src/components/scroll-up/scroll-up.js";

import NavHeader from "src/components/nav-header/nav-header";
import Header from "src/components/header/header.js";
import Cinema from "src/components/cinema/cinema.js";
import ContentOwners from "src/components/content-owners/content-owners.js";
import AboutUs from "src/components/about-us/about-us.js";
import Cinio from "src/components/cinio/cinio.js";
import Contact from "src/components/contact/contact.js";
import Footer from "../footer/footer.js";

import "src/components/main/main.scss";

const Main = ( props ) => {
  const { lang, languageOptions, handleLanguage } = props;

  const [ currentSection, setCurrentSection ] = useState( "" );

  const refs = {
    headerRef: React.createRef(),
    forCinemasRef: React.createRef(),
    forContentOwnersRef: React.createRef(),
    aboutUsRef: React.createRef(),
    managementRef: React.createRef(),
    cinioRef: React.createRef(),
    contactRef: React.createRef(),
  };

  const mainRefs = {
    "forCinemas": Data[ lang ].forCinemas,
    "forContentOwners": Data[ lang ].forContentOwners,
    "aboutUs": Data[ lang ].aboutUs,
    "cinio": Data[ lang ].cinio,
    "contact": Data[ lang ].contact,
  };

  const scrollToRef = ( id ) => {
    const refKey = id + "Ref";
    const ref = refs[ refKey ] && refs[ refKey ].current;
    if ( ref ) {
      window.scrollTo( {
        top: ref.offsetTop,
        behavior: "smooth",
      } );
    }
  };

  useEffect( () => {
    const handleScroll = () => {
      const cinemaSection = refs.forCinemasRef.current;
      if ( !cinemaSection ) return;

      const cinemaSectionTop = cinemaSection.getBoundingClientRect().top;
      if ( cinemaSectionTop < window.innerHeight * 0.2 ) {
        setCurrentSection( "forCinemas" );
      }
      else {
        setCurrentSection( "" );
      }
    };

    window.addEventListener( "scroll", handleScroll );

    return () => {
      window.removeEventListener( "scroll", handleScroll );
    };
  }, [ refs.forCinemasRef ] );

  useEffect( () => {
    handleLanguage( { target: { value: lang } } );
  }, [] );

  switch ( browserLanguage ) {
    case "en":
    case "de":
    case "nl":
      return (
        <div className="Main">
          <NavHeader
            lang={ lang }
            scrollToRef={ scrollToRef }
            currentRefs={ mainRefs }
            handleLanguage={ handleLanguage }
            languageOptions={ languageOptions }
          />
          <div className="Main__sections">
            <div ref={ refs.headerRef }>
              <Header handleNavClick={ scrollToRef } lang={ lang }  languageOptions={ languageOptions } handleLanguage={ handleLanguage } scrollToRef={ scrollToRef } />
            </div>
            <div ref={ refs.forCinemasRef }>
              <Cinema lang={ lang } />
            </div>
            <div ref={ refs.forContentOwnersRef }>
              <ContentOwners lang={ lang } />
            </div>
            <div ref={ refs.aboutUsRef }>
              <AboutUs lang={ lang } />
            </div>
            <div ref={ refs.cinioRef }>
              <Cinio lang={ lang } />
            </div>
            <div ref={ refs.contactRef }>
              <Contact handleNavClick={ scrollToRef } lang={ lang } />
            </div>
          </div>
          <Footer lang={ lang } />
          <div className={ `Main__scroll-up ${ currentSection === "forCinemas" ? "active" : "unactive" }` }>
            <ScrollUp />
          </div>
        </div>
      );
  }
};

export default Main;
