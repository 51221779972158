import React, { useState } from "react";
import Data from "src/languageData/-Data.js";
import "./content-owners.scss";

const ContentOwner = ( props ) => {
  const { lang } = props;

  const [ readMore, setReadMore ] = useState( false );

  const extraContent = (
    <div>
      <span>
        {Data[ props.lang ].contentOwnerReadMore.map( ( e ) => (
          <div key={ e }>
            <p>{e}</p>
            <br />
          </div>
        ) )}
      </span>
    </div>
  );

  const linkName = readMore ? Data[ lang ][ "readLess" ] : Data[ lang ][ "readMore" ];

  return (
    <section className="Owners">
      <div className="Owners__overlay">
        <div className="padding-global">
          <div className="Owners__content">
            <h3 className="Owners__title">
              <span className="theme-main-color">
                {Data[ lang ][ "contentOwnerTitleFirst" ]}
              </span>
            </h3>
            <h2>
              {Data[ lang ][ "contentOwnerTitleSecond" ]}
              <span className="theme-main-color">
                {Data[ lang ][ "contentOwnerTitleThird" ]}
              </span>
            </h2>
            <span className="Owners__info">
              {Data[ props.lang ].contentOwnerText.map( ( e ) => (
                <div key={ e }>
                  <p>{e}</p>
                  <br />
                </div>
              ) )}
              <br />
              <span className="text">{extraContent}</span>
              <span className="button">
                {readMore && extraContent}
                <div className="linkName" onClick={ () => setReadMore( !readMore ) }>
                  {linkName}
                </div>
              </span>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentOwner;
