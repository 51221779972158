import React from "react";
import "./scroll-up.scss";

const ScrollUp = () => {
  const scrollToTop = () => {
    window.scrollTo( { top: 0, behavior: "smooth" } );
  };

  return (
    <div className="arrowUpDiv" onClick={ scrollToTop }>
      <div className="arrowUp" />
    </div>
  );
};

export default ScrollUp;
