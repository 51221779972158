import React, { useState, useEffect } from "react";

import Data from "src/languageData/-Data.js";
import "./privacy-policy.scss";
import Footer from "../footer/footer.js";
import ScrollUp from "src/components/scroll-up/scroll-up.js";
import NavHeader from "src/components/nav-header/nav-header";

const PrivacyPolicy = ( props ) => {
  const { languageOptions, lang, handleLanguage } = props;
  const [ isTopOfScreenVisible, setIsTopOfScreenVisible ] = useState( true );

  useEffect( () => {
    const handleScroll = () => {
      setIsTopOfScreenVisible( window.scrollY === 0 );
    };

    window.addEventListener( "scroll", handleScroll );

    return () => {
      window.removeEventListener( "scroll", handleScroll );
    };
  }, [] );

  const availableLanguages = languageOptions.filter(
    languageOption => Data[ languageOption ].privacyText
  );

  const privacyText =
    availableLanguages.includes( lang ) ? Data[ lang ].privacyText : Data[ "en" ].privacyText;

  const formattedPrivacyText = privacyText.map( ( item, index ) => {
    switch ( true ) {
      case !!item.title:
        return <h3 key={ `title-${ index }` }>{ item.title }</h3>;
      case !!item.text:
        return <p key={ `text-${ index }` }>{ item.text }</p>;
      case !!item.list:
        return (
          <div className="Privacy__list" key={ `list-${ index }` }>
            <h3>{ item.listTitle }</h3>
            <ul>
              { item.list.map( ( listItem, idx ) => (
                <li key={ `listItem-${ idx }` }>{ listItem }</li>
              ) ) }
            </ul>
          </div>
        );
      case !!item.link:
        return (
          <div key={ `link-${ index }` }>
            <a href={ item.link }>{ item.linkText }</a>
          </div>
        );
      default:
        return null;
    }
  } );

  useEffect( () => {
    handleLanguage( { target: { value: lang } } );
  }, [] );

  return (
    <section className="PrivacyPolicy">
      <NavHeader
        lang={ lang }
        currentRefs={ [] }
        handleLanguage={ handleLanguage }
        languageOptions={ languageOptions }
      />
      <div className="padding-global">
        <div className="PrivacyPolicy__content">
          <h2 className="PrivacyPolicy__title"><span>
            {Data[ lang ][ "privacyFirstTitle" ]}
          </span><span className="theme-main-color">
            {Data[ lang ][ "privacySecondTitle" ]}
          </span></h2>
          <span className="PrivacyPolicy__info">{ formattedPrivacyText }</span>
        </div>
      </div>
      <div className={ `PrivacyPolicy__scroll-up ${ !isTopOfScreenVisible  ? "active" : "unactive" }` }>
        <ScrollUp />
      </div>
      <Footer lang={ lang } />
    </section>
  );
};

export default PrivacyPolicy;
