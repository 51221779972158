import detectBrowserLanguage from "detect-browser-language";

export const languages = {
  en: [
    "en",
    "en-AU",
    "en-ZA",
    "en-NZ",
    "en-IN",
    "en-CA",
    "en-US",
    "en-GB",
    "en-GB-oxendict",
    "en-gb",
    "en-au",
  ],

  de: [
    "de",
    "de-AT",
    "de-DE",
    "de-LI",
    "de-CH",
    // safari browser
    "de-de",
  ],

  nl: [
    "nl",
    "nl-BE",
    // safari browser
    "nl-nl",
  ],

  /* - Comment out below languages until we can support these translations
  fr: [
    "fr",
    "fr-CA",
    "fr-FR",
    "fr-CH",
    "fr-BE",
    // safari browser
    "fr-fr",
    "fr-ca",
  ],

  pl: [
    "pl",
    // safari browser
    "pl-pl",
  ],

  fi: [
    "fi",
    // safari browser
    "fi-fi",
  ],

  sv: [
    "sv",
    // safari browser
    "sv-se",
  ],
  */
};

let browserLanguage;
for ( const [ key, values ] of Object.entries( languages ) ) {
  if ( values.find( format => format === detectBrowserLanguage() ) ) {
    browserLanguage = key;
  }
}

if ( !browserLanguage ) {
  // default to the English language
  browserLanguage = "en";
}

export default browserLanguage;
