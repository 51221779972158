import React, { useState, useEffect } from "react";
import Data from "src/languageData/-Data.js";
import "./data-deletion.scss";
import Footer from "../footer/footer.js";
import ScrollUp from "../scroll-up/scroll-up.js";
import NavHeader from "src/components/nav-header/nav-header";

const DataDeletion = ( props ) => {
  const { languageOptions, lang, handleLanguage } = props;

  const [ isTopOfScreenVisible, setIsTopOfScreenVisible ] = useState( true );

  useEffect( () => {
    const handleScroll = () => {
      setIsTopOfScreenVisible( window.scrollY === 0 );
    };

    window.addEventListener( "scroll", handleScroll );

    return () => {
      window.removeEventListener( "scroll", handleScroll );
    };
  }, [] );

  const availableLanguages = languageOptions.filter(
    ( languageOption ) => Data[ languageOption ].dataDeletionText
  );

  const dataDeletionText =
    availableLanguages.includes( lang )
      ? Data[ lang ].dataDeletionText
      : Data[ "en" ].dataDeletionText;

  const formattedDataDeletionText = dataDeletionText.map( ( item, index ) => {
    switch ( true ) {
      case !!item.title:
        return <h3 key={ `title-${ index }` }>{item.title}</h3>;
      case !!item.text:
        return <p key={ `text-${ index }` }>{item.text}</p>;
      case !!item.list:
        return (
          <div className="DataDeletion__list" key={ `list-${ index }` }>
            <h3>{item.listTitle}</h3>
            <ul>
              {item.list.map( ( listItem, idx ) => (
                <li key={ `listItem-${ idx }` }>{listItem}</li>
              ) )}
            </ul>
          </div>
        );
      case !!item.link:
        return (
          <div className="DataDeletion__link" key={ `link-${ index }` }>
            <a href={ item.link } target="_blank">{item.linkText}</a>
          </div>
        );
      default:
        return null;
    }
  } );

  return (
    <section className="DataDeletion">
      <NavHeader
        lang={ lang }
        currentRefs={ [] }
        handleLanguage={ handleLanguage }
        languageOptions={ languageOptions }
      />
      <div className="language">
        <select onChange={ handleLanguage }>
          {availableLanguages.map( ( option, index ) => (
            <option className="option" key={ index } value={ option }>
              {option.toUpperCase()}
            </option>
          ) )}
        </select>
      </div>
      <div className="padding-global">
        <div className="DataDeletion__content">
          <h2 className="DataDeletion__title">
            <span>{Data[ lang ][ "dataDeletionFirstTitle" ]}</span>
            <span className="theme-main-color">
              {Data[ lang ][ "dataDeletionSecondTitle" ]}
            </span>
          </h2>
          <span className="DataDeletion__info">{formattedDataDeletionText}</span>
        </div>
      </div>
      <div className={ `DataDeletion__scroll-up ${ !isTopOfScreenVisible  ? "active" : "unactive" }` }>
        <ScrollUp />
      </div>
      <Footer lang={ lang } />
    </section>
  );
};

export default DataDeletion;
