import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./nav-header.scss";
import Data from "src/languageData/-Data.js";

import enFlagIcon from "src/components/assets/en-flag.webp";
import nlFlagIcon from "src/components/assets/nl-flag.webp";
import deFlagIcon from "src/components/assets/de-flag.webp";

import Logo from "src/components/assets/logo-gofilex-date.webp";
import LanguageSelector from "src/components/language-selector/language-selector";

const NavHeader = ( props ) => {
  const { scrollToRef, currentRefs, languageOptions, handleLanguage, lang } = props;
  const [ menuOpen, setMenuOpen ] = useState( false );
  const [ menuHeight, setMenuHeight ] = useState( 0 );
  const menuRef = useRef( null );
  const location = useLocation();

  let filterKey = "";
  if ( location.pathname === "/privacy-policy" ) {
    filterKey = "privacyText";
  }
  else if ( location.pathname === "/data-deletion" ) {
    filterKey = "dataDeletionText";
  }

  const availableLanguages = filterKey ? languageOptions.filter(
    languageOption => Data[ languageOption ][ filterKey ]
  ) : languageOptions;

  const toggleMenu = () => {
    setMenuOpen( ( prevMenuOpen ) => !prevMenuOpen );
    if ( !menuOpen ) {
      setMenuHeight( "calc( 100vh )" );
    }
    else {
      setMenuHeight( 0 );
    }
  };

  const handleScrollNav = ( key ) => {
    scrollToRef( key );
    toggleMenu();
  };

  const scrollToTop = () => {
    window.scrollTo( {
      top: 0,
      behavior: "smooth",
    } );
  };

  useEffect( () => {
    const displayMenu = () => {
      if ( currentRefs.length === 0 || window.innerWidth > 1085 ) {
        menuRef.current.style.display = "none";
      }
      else {
        menuRef.current.style.display = "flex";
      }
    };

    displayMenu();

    window.addEventListener( "resize", displayMenu );

    return () => {
      window.removeEventListener( "resize", displayMenu );
    };
  }, [ currentRefs ] );

  let currentFlag = enFlagIcon;
  switch ( lang ) {
    case "en":
      currentFlag = enFlagIcon;
      break;
    case "nl":
      currentFlag = nlFlagIcon;
      break;
    case "de":
      currentFlag = deFlagIcon;
      break;
  }

  return (
    <div className="NavHeader">
      <div className="padding-global">
        <div className="NavBar">
          <div className="NavBar__logo">
            {location.pathname !== "/" ? (
              <a href="/"> <img src={ Logo } alt="company-logo" /></a>
            ) : (
              <Link to="/" onClick={ scrollToTop }>
                <img src={ Logo } alt="company-logo" />
              </Link>
            )}
          </div>
          <div className="NavBar__items">
            {Object.keys( currentRefs ).map( ( key ) => {
              return (
                <div
                  className="NavBar__scroll-link"
                  key={ key }
                  onClick={ () => scrollToRef( key ) }
                >
                  {Data[ lang ][ key ].toUpperCase()}
                </div>
              );
            } )}
            <div
              className="NavBar__language-selector"
            >
              <LanguageSelector lang={ lang } currentFlag={ currentFlag } availableLanguages={ availableLanguages } handleLanguage={ handleLanguage  } />
            </div>
          </div>
          <div className="NavBar__menu" ref={ menuRef } role="button" onClick={ toggleMenu }>
            <i className={ menuOpen ? "open" : "" } />
            <i className={ menuOpen ? "open" : "" } />
            <i className={ menuOpen ? "open" : "" } />
          </div>
        </div>
        <div
          className={ `menu-items ${ menuOpen ? "open" : "closed" }` }
          style={ { height: menuHeight } }
        >
          <div className="NavBar__menu-scroll-links">
            {Object.keys( currentRefs ).map( ( key ) => {
              return (
                <div
                  className="NavBar__scroll-link"
                  key={ key }
                  onClick={ () => handleScrollNav( key ) }
                >
                  <div className="NavBar__scroll-link__value">
                    {Data[ lang ][ key ].toUpperCase()}
                  </div>
                </div>
              );
            } )}
          </div>

          <div
            className="NavBar__language-selector"
          >
            <LanguageSelector toggleMenu={ toggleMenu }lang={ lang } currentFlag={ currentFlag } availableLanguages={ availableLanguages } handleLanguage={ handleLanguage }  />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
